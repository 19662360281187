.order__items__image {
   max-width: 300px;
   width: 100%;
   height: 200px;
}

@media (max-width:1000px) {
    .profile {
        flex-direction: column;
    }
}

@media (max-width:900px) {
    .order__wrapp {
        flex-direction: column;
    }

    .order__left {
        margin-bottom: 50px;
    }
}

@media (max-width:768px) {
    .order__items {
        flex-direction: column;
    }

    .order__heading {
        font-size: 25px;
        text-align: center;
        margin-top: 50px;
    }

    .order__left {
        margin: 0;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .order__items__products {
        margin-left: 0;
    }

    .order__payments {
        flex-direction: column;
    }

}

@media (max-width:400px) {
    .order__left__text {
        width: 250px;
    }

    .order__user__hr {
        width: 300px;
    }

    .order__user__input {
        width: 300px;
    }

    .order__user__heading {
        font-size: 20px;
    }

    .payments_left {
        margin-right: 0;
        margin-bottom: 30px;
    }

    .paymentts__left__input {
        // width: 300px;
    }

    .payments__left__button {
        display: block;
        width: 300px;
    }
}

@media (max-width:350px) {
    .order__left__text {
        width: 250px;
    }

    .order__user__hr {
        width: 250px;
    }

    .order__user__input {
        width: 250px;
    }

    .payments__left__button {
        display: block;
        width: 200px;
    }

}