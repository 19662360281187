
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: blue;
    color: white;
    padding: 1em;
    border-radius: 8px;
    animation: fade-in-out 2s ease;
    z-index: 1000;
}

.modal-content {
    text-align: center;
    font-size: 1.5em;
}

@keyframes fade-in-out {
    0% { opacity: 0; transform: translateY(-20px); }
    25% { opacity: 1; transform: translateY(0); }
    75% { opacity: 1; transform: translateY(0); }
    100% { opacity: 0; transform: translateY(-20px); }
}


.text-ellipsis {
    width: 200px; /* kenglikni belgilang */
    white-space: nowrap; /* Matnni bitta qatorda saqlash */
    overflow: hidden; /* Kenglikdan oshgan qismini yashirish */
    text-overflow: ellipsis; /* O'sha joyda "..." chiqarish */
}


.main__wrapp__img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.main {
    .slick-next::before {
        color: #000;
    }
    
    .slick-prev::before {
        color: #000;
    }
}

.main__wrapper {

    &__item {
        max-width: 320px;
        // min-height: 400px;
    }

    &__img {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }

}

@media only screen and (max-width:768px) {
    .main {
        &__img {
            width: 100%;
            display: block;
            justify-content: center;
            margin-bottom: 50px;
            // margin: 0 auto;
        }

        &__link {
           display: flex;
           justify-content: center;
           text-align: center;
           margin-top: 30px;
        }


        &__list {
            justify-content: center;
            text-align: center;
        }

        &__item {
            width: 400px;
            height: 650px;
            border: none;
            border: 1px solid black;
            margin-top: 100px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &__text {
            text-align: center;
            margin: 0 auto;
        }

        &__cart-img {
            // margin-top: 10px;
            position: relative;
            top: 15px;
        }

        .slick-arrow::before{
            background: #000!important;
            border: 2px solid black;
            color: black;
        }

        .slick-next {
            background: #000;
            border: 2px solid black;

        }
    }

    .main__wrapper {

        &__img {
            width: 100%;
            display: block;
            justify-content: center;
            margin-bottom: 50px;
            // margin: 0 auto;
        }

        &__link {
           display: flex;
           justify-content: center;
           text-align: center;
           margin-top: 30px;
        }

        &__list {
            justify-content: center;
            text-align: center;
            flex-direction: column;
        }

        &__item {
            width: 400px;
            height: 650px;
            border: none;
            border: 1px solid black;
            margin-top: 100px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &__text {
            text-align: center;
            margin: 0 auto;
        }

        &__cart-img {
            // margin-top: 10px;
            position: relative;
            top: 15px;
        }
    }

    .main__wrapp {
        
        &__img {
            width: 100%;
            display: block;
            justify-content: center;
            margin-bottom: 50px;
            // margin: 0 auto;
        }

        &__link {
           display: flex;
           justify-content: center;
           text-align: center;
           margin-top: 30px;
        }

        &__list {
            flex-direction: column;
            justify-content: center;
            text-align: center;
        }

        &__item {
            width: 350px;
            height: 650px;
            border: none;
            border: 1px solid black;
            margin-top: 100px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &__text {
            text-align: center;
            margin: 0 auto;
        }

        &__cart-img {
            position: relative;
            top: 15px;
        }
    }
}


@media only screen and (max-width:375px) {
        .main {

            &__wrapp {
                flex-direction: column;
                justify-content: center;
                margin: 0 auto;
            }

            &__cart-img {
                margin: 0 auto;
            }
        }

        .main__wrapp {

            &__item {
                width: 330px;
            }

            &__wrapp {
                flex-direction: column;
                justify-content: center;
                margin: 0 auto;
            }

            &__cart-img {
                margin: 0 auto;
            }
        }

        .main__wrapper {

            &__item {
                width: 330px;
            }

            &__wrapp {
                flex-direction: column;
                justify-content: center;
                margin: 0 auto;
            }

            &__cart-img {
                margin: 0 auto;
            }
        }
}

// mobile 410
@media only screen and (max-width:410px) {
    .main {

        &__item {
            max-width: 300px;
        }

        &__text {
            max-width: 250px;
        }
    }

    .main__wrapp {

        &__item {
            max-width: 300px;
            justify-content: center;
            margin: 0 auto;
            margin-top: 10px;
            // margin-right: 0;
        }

        &__text {
            max-width: 200px;
        }
    }

    .main__wrapper {

        &__item {
            max-width: 300px;
            margin: 0 auto;
            margin-top: 10px;
        }

        &__text {
            max-width: 200px;
        }
    }
}

// mobile 310
@media only screen and (max-width:325px) {
    .main {

        &__item {
            max-width: 250px;
        }

        &__text {
            max-width: 200px;
        }
    }

    .main__wrapp {

        &__item {
            max-width: 250px;
            margin: 0 auto;
            margin-top: 10px;
        }

        &__text {
            max-width: 200px;
        }
    }

    .main__wrapper {

        &__item {
            max-width: 250px;
            margin: 0 auto;
            margin-top: 10px;   
        }

        &__text {
            max-width: 200px;
        }
    }
}