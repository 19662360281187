html {
    overflow-x: hidden !important;
    scroll-behavior: smooth;
    box-sizing: border-box;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    color: rgb(0, 0, 104);
    overflow-x: hidden;
}

.container {
    max-width: 1480px;
    margin: 0 auto;
    padding: 0 20px;
}

ul , ol {
    list-style: none;
}

a {
    text-decoration: none;
    display: inline-block;
}

main {
    flex-grow: 1;
}

.active {
    background-color: rgb(148, 237, 237);
    border-radius: 8px;
}

.text-wrapper.expand {
    max-height: none; /* Cheksiz balandlik */
}

.text-ellipsis {
    width: 200px; /* kenglikni belgilang */
    white-space: nowrap; /* Matnni bitta qatorda saqlash */
    overflow: hidden; /* Kenglikdan oshgan qismini yashirish */
    text-overflow: ellipsis; /* O'sha joyda "..." chiqarish */
}

.main {

    &__item {
        max-width: 320px;
        // min-height: 400px;
    }

    &__img {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }
}