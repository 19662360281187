
// .order {
//     .slick-next::before {
//         color: #000;
//     }
        
//     .slick-prev::before {
//         color: #000;
//     }
// }

@media (max-width:1346px) {

    .carts__counts {
        width: 200px;
        display: flex;
    }
}

@media (max-width:1280px) {
   .order__wrapp {
    flex-direction: column;
   }

   .order__wrapp__right {
    margin-left: 0;
   }

}

@media (max-width:1145px) {

    .order__item {
        flex-direction: column;
    }
}

// order tablet
@media (max-width:768px) {
    .order {

        &__item {
            width: 450px;
            min-height: 700px;
            border: none;
            border: 1px solid black;
            margin: 0 auto;
            margin-top: 100px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &__item__image {
            width: 400px;
            position: relative;
            left: 25px;
        }
    }
}

@media (max-width:600px) {
    .order__item__image {
        width: 400px;
    }
}

@media (max-width:530px) {
    .order {
        .carts__counts {
            width: 150px;
            display: flex;
            margin-bottom: 40px;
        }

        &__item__image {
            width: 300px;
            position: relative;
            left: 80px;
        }
    }
}

// order mobile 
@media (max-width:475px) {
    .order {

        &__text {
            width: 280px;
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
            hyphens: auto;
        }

        .order__wrapp__right {
            width: 200px;
        }

        .slider-container {
            margin-bottom: 0;
            position: relative;
            z-index: -100;
            margin: 0;
            padding: 0;
            right: 30px;
        }

        &__item__wrapp {
            margin-left: 50px;
        }

        &__item__image {
            width: 250px;
           position: relative;
           right: 50px;
        }

        &__item {
            width: 400px;
            min-height: 500px;
            border: none;
            border: 1px solid black;
            margin: 0 auto;
            margin-top: 200px;
            flex-direction: column;
            justify-content: center;
            align-items: center;   
        }

        &__img {
            width: 300px;
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
        }

        &__bottom {
            position: relative;
            bottom: 40px;
        }
    }
}

// mobile 435
@media (max-width:435px) {
    .order {

        &__item {
            width: 300px;
            min-height: 750px;
            border: none;
            border: 1px solid black;
            margin: 0 auto;
            margin-top: 100px;
            flex-direction: column;
            justify-content: center;
            align-items: center;   
        }

        &__img {
            margin-top: 50px;
        }

        &__name {
            text-align: center;
        }

        &__text {
            width: 250px;
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
            hyphens: auto;
        }
    }
}

@media (max-width:410px) {
    .order {

        &__text {
            width: 250px;
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
            hyphens: auto;
        }

        &__item__image {
            max-width: 250px;
            width: 100%;
        }

        .order__wrapp__right {
           width: 200px;
        }
    }    
}

// order mobile 
@media (max-width:375px) {
    .order {

        .order__item__image {
            width: 250px !important;
        }

        &__item {
           box-shadow: none;
        }

        &__name {
            font-size: 25px;
        }

        &__text {
            width: 200px;
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
            hyphens: auto;
        }
    }
}

@media (max-width:350px) {

}

@media (max-width:330px) {
    .order {

        &__item {
                width: 250px;
                min-height: 750px;
                border: none;
                margin: 0 auto;
                margin-top: 100px;
                flex-direction: column;
                justify-content: center;
                align-items: center;   
                background: transparent;
        }

        &__img {
            margin-top: 50px;
        }

        &__name {
            text-align: center;
        }

        &__text {
            width: 200px;
            // text-align: center;
        }

        .order__wrapp__right {
            width: 180px;
        }

    }
}