@media (max-width:768px) {
    .footer {
        width: 100%;
        justify-content: center;
        margin-top: 100px;
    }
}

@media (max-width:375px) {
    .footer {
        width: 100%;
        justify-content: center;
        margin-top: 100px;
    }
}