// logout
.user__link {
    position: relative;
    display: inline-block; /* Horizontda joylashishi uchun */
    cursor: pointer; /* Ko'rsatgichni tugmada ko'rsatish */
}

.logout {
    position: absolute;
    top: 78%; /* Profil tugmasi ostida ko'rinadi */
    right: 40px;
    background-color: #f8f8f8; /* Tugma fon rangi */
    border: 1px solid #ccc; /* Tugma chegarasi */
    padding: 5px; /* Tugma ichidagi joy */
    z-index: 1; /* Boshqa elementlardan yuqorida ko'rinishi uchun */
    border-radius: 7px;
}

.header {

    &__menu {
        display: none;
    }

    &__burger {
        display: none;
    }

}

@media only screen and (max-width:1200px) {
    .header {
        &__list {
            display: none;
        }
    }
}

@media only screen and (max-width:930px) {
    .header {

        &__menu {
            display: flex;
            justify-content: space-between;
            width: 500px;
            height: 100vh;
            background-color:rgb(0, 0, 104);
            position: absolute;
            top:0;
            right:0;
            font-size: 20px;
            transition: all 2s ease-in-out;
            color: white;
        }

        &__list {
           display: none;
        }

        &__burger {
            display: block;
        }
    }   
}

// tablet
@media only screen and (max-width:768px) {
    .header {

        &__menu {
            width: 500px;
            height: 100vh;
            background-color: rgb(0, 0, 104);
            color: white;
            position: absolute;
            top:0;
            right:0;
            font-size: 20px;
            transition: all 2s ease-in-out;
        }

        &__karzinka {
            margin-top: 10px;
        }

        &__menu:hover {
            border-bottom: 2px solid white;
        }

        &__list {
           display: none;
        }

        &__burger {
            display: block;
        }
    }   
}

// mobile 480 
@media only screen and (max-width:480px) { 
    .header {

        &__image {
            width: 150px;
        }

        &__menu--item {
            position: relative;
            left: 120px;
        }
    }
}

// mobile 435
@media only screen and (max-width:435px) {

    .header {
        &__image {
            display: none;
        }

        &__logo--heading {
            display: block;
        }


    }
    
}

@media only screen and (max-width:400px) { 
    .header {


        &__image {
            display: none;
        }

        &__menu--item {
            position: relative;
            left: 120px;
        }
    }
}

// mobile 375
@media only screen and (max-width:375px) {
    .header {

        &__image {
            display: none;
        }

        &__menu--item {
            position: relative;
            left: 120px;
        }

        &__burger {
            display: block;
        }

        &__karzinka {
           position: relative;
           left: 125px;
        }

    }
    
}

// mobile 350
@media only screen and (max-width:350px) {

    .header {

        &__menu--list {
            position: relative;
            left: 70px;
        }

        &__select {
            padding: 5px;
        }

        &__enter {
            padding: 5px;
        }

        &__karzinka {
            position: relative;
            left: 125px;
        }
    }
    
}

@media (max-width:350px) {
    .header__karzinka {
        transform: translate(65px);
    }
}