// address page

@media (max-width:768px) {
    .address {
        width: 400px;
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        // justify-content: center;
    }
}

@media (max-width:640px) {
    .select {
    flex-direction: column;
    }

    .is__LLC {
        margin-top: 50px;
    }
}

@media (max-width:430px) {
    .address {
        width: 300px;
        margin: 0 auto;
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &__title {
            width: 300px;
        }

    }

}