@media only screen and (max-width:768px) {
    .hero__section {
        width: 100%;
    }

    .hero {

        &__heading {
            width: 375px;
            font-size: 38px;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
        }

        &__wrapper {
            width: 450px;
            text-align: center; 
            margin: 0 auto;
        }
    }
}


// mobile 470
@media only screen and (max-width:470px) {
    .hero {

        &__wrapper {
            width: 350px;
        }

        &__heading {
            width: 300px;
            font-size: 20px;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
        }

        &__text {
            width: 300px;
            font-size: 18px;
        }

        &__button {
            margin: 0 auto;
            margin-top: 20px;
        }


        &__wrapper {
            text-align: center;   
        }
    }
}

// mobile 375
@media only screen and (max-width:375px) {
    .hero {
        width: 100%;

        &__heading {
            width: 300px;
            font-size: 25px;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
        }

        &__text {
            width: 300px;
        }


        &__wrapper {
            text-align: center;   
        }
    }
}

// mobile 350
@media only screen and (max-width:350px) {
    .hero {
        width: 100%;

        &__heading {
            width: 250px;
            font-size: 25px;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
        }

        &__text {
            width: 250px;
        }

        &__wrapper {
            width: 250px;
            text-align: center;   
        }
    }
}