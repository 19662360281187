/* Input type number belgilarini yashirish */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.carts__item--image {
    width: 100%;
    object-fit: cover;
    height: 200px;
}

.text-ellipsis {
    width: 200px; /* kenglikni belgilang */
    white-space: nowrap; /* Matnni bitta qatorda saqlash */
    overflow: hidden; /* Kenglikdan oshgan qismini yashirish */
    text-overflow: ellipsis; /* O'sha joyda "..." chiqarish */
}

@media (max-width:1200px) {
    .carts {
        flex-direction: column;
    }
}

@media (max-width:980px) {
    .carts {
        flex-direction: column;

        &__item {
            flex-direction: column;
        }

        &__item--delete {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            left: 0;
        }

        &__item--price {
            position: relative;
            top: 0;
        }
    }
}

@media (max-width: 768px) {
    .slider__image__wrapper {
        width: 500px;
        height: 300px;
    }
}

@media (max-width:768px) {
    .carts {
        flex-direction: column;

        &__item--text {
            margin-top: 20px;
        }

        &__counts {
            position: relative;
            left: 0;
            bottom: 0;
            top: 10px;
            // z-index: -100;
        }

        &__item {
            margin-top: 50px;
        }

        &__item--image {
           width: 600px;
           height: 300px;
        }

        &__item--delete {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            left: 0;
        }

        &__item--price {
            position: relative;
            top: 0;
        }

        &__item--text {
            width: 500px;
        }

        &__item {
            flex-direction: column;
        }

        &__right {
            width: 100%;
            margin-left: 0;
        }
    }
}


@media (max-width:560px) {
    .carts {

        &__item--image {
            width: 450px;
            height: 250px;
        }

        &__item--text {
            width: 450px;
            // text-align: center;
        }
    }
}

@media (max-width:530px) {
    .carts {

        .carts__counts {
            width: 150px;
            display: flex;
        }

        &__item--image {
            width: 450px;
            height: 250px;
        }

        &__item--text {
            width: 350px;
        }

        &__counts  {
            width: 100px;
            position: relative;
            top: 20px;
            left: 0;
        }

        &__item--wrapper {
            display: flex;
            flex-direction: column;
        }

        &__item--delete {
            margin-top: 30px;
        }
    }
}

@media (max-width:420px) {
    .carts {
        &__item--text {
            width: 300px;
        }        
    }
}

@media (max-width:360px) {
    .carts {
        &__item--text {
            width: 250px;
        }  
        
    }
}

@media (max-width:330px) {
    .carts {
        &__item--text {
            max-width: 200px;
            // text-align: center;
        }
        
    }
}