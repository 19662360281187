@media only screen and (max-width:768px) {
    .notification_left {
        margin-top: 50px;
    }

    .profile__left__title {
        font-size: 25px;
    }
}

@media only screen and (max-width:320px) {
    .profile__hr {
        width: 250px;
    }

    .profile__right__link {
        width: 250px;
    }

    .profile__left__title {
        font-size: 20px;
    }
}

