.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: blue;
    color: white;
    padding: 1em;
    border-radius: 8px;
    animation: fade-in-out 2s ease;
    z-index: 1000;
}

.modal-content {
    text-align: center;
    font-size: 1.5em;
}

@keyframes fade-in-out {
    0% { opacity: 0; transform: translateY(-20px); }
    25% { opacity: 1; transform: translateY(0); }
    75% { opacity: 1; transform: translateY(0); }
    100% { opacity: 0; transform: translateY(-20px); }
}

.products__item__img {
    width: 300px;
    height: 250px;
}

// responsive
@media (max-width:800px) {
    .products {
        flex-direction: column;

        &__left {
            margin-bottom: 50px;
        }
    }

    .products__list {
        flex-direction: column;
    }

    .main__text {
        margin: 0;
        padding: 0;
    }
}

@media (max-width:475px) {
    .products {
        flex-direction: column;

        &__left {
            width: 400px;
            margin-bottom: 50px;
        }
    }

    .products__item {
        width: 320px;
    }
}

@media (max-width:375px) {
    .products {

        &__item {
            width: 300px;
        }
        
        &__left {
            width: 250px;
            margin-bottom: 50px;
        }

        &__heading {
            font-size: 20px;
        }
    }
}

@media (max-width:350px) {
    .products {
        
        &__item {
            width: 260px;
        }

        &__left {
            width: 200px;
            margin-bottom: 50px;
        }

        &__heading {
            font-size: 20px;
        }
    }

    .main__item {
        width: 350px;
    }
}
