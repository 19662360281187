
.notification {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    padding: 16px 24px;
    border-radius: 8px;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    opacity: 0;
    animation: fadeInOut 3s ease-in-out forwards;
    z-index: 1000;

    &.success {
        background-color: #28a745;
    }

    &.error {
        background-color: #dc3545;
    }
}

@keyframes fadeInOut {
    0%, 100% { opacity: 0; }
    10%, 90% { opacity: 1; }
}


.payments__image {
    width: 200px;
    height: 300px;
}

@media only screen and (max-width:768px) {
    .payments_left {
        flex-direction: column;
        margin-top: 50px;
        margin-left: 0;
    }

    .payments__data {
        margin-left: 0;
    }

    .payments__data--item {
        flex-direction: column;
    }
}