
.notification {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    padding: 16px 24px;
    border-radius: 8px;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    opacity: 0;
    animation: fadeInOut 3s ease-in-out forwards;
    z-index: 1000;

    &.success {
        background-color: #28a745;
    }

    &.error {
        background-color: #dc3545;
    }
}

@keyframes fadeInOut {
    0%, 100% { opacity: 0; }
    10%, 90% { opacity: 1; }
}
/* contact responsive */

// media 930
@media (max-width:930px) {
    .contact {
        display: flex;
        justify-content: space-between;


       &__left {
         margin-left: 0;
         margin-right: 0;
       }
    }
}

@media (max-width:768px) {
    .contact {
        margin-left: 50px;
        display: flex;
        // flex-direction: column;

        &__left {
            margin: 0;
            margin-right: 100px;
        }

        &__form-button {
           
        }
    }
}

@media (max-width:700px) {
    .contact {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &__right {
            margin-top: 100px;
        }
    }

}

// mobile 400
@media (max-width:400px) {
    .contact {
        margin-left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &__left {
            margin: 0;
        }
       
        &__form-input {
            max-width: 300px;
        }

        &__right {
            margin-top: 100px;
        }


    }

}

// mobile 375
@media (max-width:375px) {
    .contact {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &__title {
            font-size: 30px;
            text-align: center;
        }

        &__span {
            width: 250px;
            text-align: center;
        }

        &__wrapp {
            flex-direction: column;
            justify-content: center;
        }
       
        &__form-input {
            max-width: 250px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
        }

        &__form-button {
            display: block;
            width: 150px;
            height: 50px;
            margin: 0 auto;
            margin-top: 20px;
        }

        &__text {
            width: 300px;
            text-align: center;
        }

        &__right {
            margin-top: 100px;
        }
    }

}

@media (max-width:320px) {
    .contact {

        &__form-input {
           margin-left: 0;
           margin-right: 0;
           margin-right: 30px;
        }
    }
}