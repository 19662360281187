@media only screen and (max-width:768px) {
    .profile {
        flex-direction: column;

        &__left__left {
            margin-left: 0;
        }

        &__right__link {
            width: 100%;
            display: block;
        }

        &__left {
           margin-top: 50px;
        }

        &__left--right {
            margin-bottom: 30px;
        }

        &__left__data {
            width: 500px;
            flex-direction: column;
        }

        &__left__title {
            font-size: 30px;
        }
    }
}

@media only screen and (max-width:540px) {
    .profile {

        &__left__data {
            max-width: 400px;
        }

        &__left__title {
            font-size: 25px;
        }
    }
}

@media only screen and (max-width:440px) {
    .profile {

        &__left__text {
            width: 350px;
        }

        &__left__data {
            max-width: 300px;
        }

        &__right--hr {
            max-width: 300px;
        }
    }
}

@media only screen and (max-width:380px) {
    .profile {

        &__left__text {
            width: 250px;
        }

        &__left__data {
            max-width: 250px;
        }

        &__right--hr {
            max-width: 200px;
        }

        &__left__title {
            font-size: 20px;
        }

    }
}


@media only screen and (max-width:340px) {
    .profile {
        &__right--hr {
            width: 250px;
        }

        &__right__link {
            width: 250px;
        }

    }
}