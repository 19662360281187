.messages__form {
    width: 100%;
    max-width: 100%;
}

.messages__input {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box; /* Padding va borderlarni kenglikka qo'shadi */
}

// error modal message
.error-modal {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    background-color: rgba(255, 0, 0, 0.8);
    padding: 20px;
    border-radius: 8px;
    color: #fff;
    text-align: center;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    z-index: 1000;

    &__content {
        font-size: 18px;
    }
}

// Xatolik modaliga animatsiya qo'shish
.error-modal-fade-in {
    animation: fadeIn 0.5s ease-in-out forwards;
}

.error-modal-fade-out {
    animation: fadeOut 0.5s ease-in-out forwards;
}


// modal send message
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: blue;
    padding: 20px;
    border-radius: 8px;
    color: #fff;
    text-align: center;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    z-index: 1000;

    &__content {
        font-size: 18px;
    }
}

// Animation effect
.modal-fade-in {
    animation: fadeIn 0.5s ease-in-out forwards;
}

.modal-fade-out {
    animation: fadeOut 0.5s ease-in-out forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}


@media only screen and (max-width:768px) {
    .messages {
        flex-direction: column;
    }

    .messages_left {
        margin-top: 50px;
        margin-left: 0;
    }

    .messages__message {
        position: relative;
        left: 0;
    }

    .messages__form {
        max-width: 500px;
    }

    .messages__input {

    }


}

@media only screen and (max-width:768px) {
    
}

@media only screen and (max-width:768px) {
    
}

@media only screen and (max-width:768px) {
    
}